// BASE IMPORTS
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef, useReducer, useCallback } from "react";
import { fetchProjectData } from './editorAPI';
import { editorReducer } from './EditorReducer';
import axios from 'axios';

// CONTEXT & MODULES
import UserContext from '../../UserContext';
import { EditorContext } from "./EditorContext";
import { KeyboardShortcutsProvider } from "./KeyboardContext";
import { useExecute } from "./editorActions";

// COMPONENTS
import { mergeThemeVariables } from "./sidebar/theme-editor/helpers";
import ProjectView from "./ProjectView";
import CanvasView from "./CanvasView";
import {v1 as uuidv1 } from 'uuid' // for everything else
import * as projectsDB from '../../api/projectsAPI'
import { saveToLocalStorage } from "./localStorage";
import SettingsModal from "../settings/SettingsModal";
import Header from "./header/Header";
import { set } from "lodash";
import Spinner from "../../components/Spinner";
import { DSContext } from "./DSContext";
import { CursorContext } from "./CursorContext";
import { TemplatesContext } from "./TemplatesContext";
import Navigation from './overview/sidebar/Navigation';

const initialState = {
  project: null,
  designSystem: null,
  actionsHistory: {
    past: [],
    future: []
  },
  view: {
    mode: 'editor', 
    rightSide: '',
    assets: null,
    leftSide: true,
    previewMode: false,
    showOutlines: false,
    contextMenu: {x: null, y: null},
    loading: [],
    overview: true,
    overviewSidebar: true,
    inOverview: 'folders',
    miniatures: false, 
    disableDnD: false, 
    customWidth: null,
    canvasWidth: null, 
    canvasHeight: null, 
    editingComponent: false, 
    modal: false, 
    sidebarWidth: 320,
    columnWidth: 272, 
    scale: 60, 
    showNotes: true, 
    showAllFolders: false,
    isMobile: false, 
    settings: null,
    miniNav: true,
    nightMode: false,
  },
  selector: {
    folder: null,
    page: null,
    frame: null,
    section: null,
    object: null,
    component: null, 
    folderFilter: null, 
  },
  dndState: { // we need these variables for DOM updates, the rest of DND variables are stored in dndRefs in DnDContext
    isDragging: false,
    isDraggingFromLibrary: false,
    draggingOverItem: null,
    draggingItem: null, 
    collectBounds: true,
  }, 
  
};

export default function EditorPage() {
  // APP & USER
  const { projectID } = useParams();
  const { user, isProduction } = useContext(UserContext);
  const token = user ? user.token : null;

  const [panState, setPanState] = useState({
    translate: { x: 0, y: 0 },
    zoom: 75,
    isPanning: false,
    isActive: true, 
    fitCanvas: true,
    canvasDimensions: { width: null, height: null },
  });
  const [hoveredObjId, setHoveredObjId] = useState(null)
  
  const [state, dispatch] = useReducer(editorReducer, initialState);
  const { project, selector, designSystem, actionsHistory, view, dndState } = state;
  const [ notifications, setNotifications ] = useState([])
  
  // FETCH PROJECT DATA & GET LAST VIEW
  useEffect(() => {
    if (user && user.token) {
      const savedViewState = localStorage.getItem(`project-${projectID}-viewState`);
      
      if (savedViewState) {
        
        const parsedViewState = JSON.parse(savedViewState);
          
        const viewPayload = {...view, 
          leftSide: parsedViewState.leftSide, 
          rightSide: parsedViewState.rightSide,
          overview: parsedViewState.overview, 
          inOverview: parsedViewState.inOverview,
        };
      
        dispatch({ type: 'SET_VIEW', payload: viewPayload });
      }

      fetchProjectData(projectID, user, dispatch, state.selector);
      
    }
  }, [projectID, user?.id, dispatch]);
  
  // ACTION ROUTER - FOR ACTIONS CHECK EDITORACTIONS.JS
  const [actionQueue, setActionQueue] = useState([]);
  const isProcessingRef = useRef(false);
  const iterationCountRef = useRef(0);
  
  const MAX_QUEUE_SIZE = 200
  const execute = useExecute(token);
  // console.log(actionQueue)
  function handleAction(action) {
    setActionQueue(prevQueue => {
      if (prevQueue.length >= MAX_QUEUE_SIZE) {
        throw new Error("Action queue limit exceeded. Cannot add more actions.");
      }
      return [...prevQueue, action];
    });
  }

  async function processQueue() {
    if (actionQueue.length === 0 || isProcessingRef.current) {
      return;
    }

    isProcessingRef.current = true;
    iterationCountRef.current += 1;

    try {
      const currentAction = actionQueue[0];

      const {
        project: updatedProject,
        selector: updatedSelector,
        designSystem: updatedDesignSystem,
        history: updatedHistory,
      } = await execute(project, selector, designSystem, currentAction);

      if (updatedProject) {
        dispatch({ type: 'SET_PROJECT', payload: updatedProject });
      }
      if (updatedSelector) {
        dispatch({ type: 'SET_SELECTOR', payload: updatedSelector });
      }
      if (updatedDesignSystem) {
        dispatch({ type: 'SET_DESIGN_SYSTEM', payload: updatedDesignSystem });
      }
      if (updatedHistory) {
        dispatch({ type: 'SET_HISTORY', payload: updatedHistory });
      }

      // Remove the processed action from the queue
      setActionQueue(prevQueue => prevQueue.slice(1));
    } catch (error) {
      // console.error("Error processing action:", error);
    } finally {
      isProcessingRef.current = false;
    }
  }

  useEffect(() => {
    processQueue();
  }, [actionQueue]);
  // console.log(actionsHistory)


  // SAVE TO LOCAL STORAGE
  useEffect(() => {
    if (project && project.project) {
      saveToLocalStorage({projectID, state});
    }
  }, [projectID, state.selector, state.view]);

  
  const currentThemeId = designSystem?.themes?.find(t => t.id == project?.project?.current_theme_id)?.id || designSystem?.designSystem?.default_theme_id;

  function handleSetDesignSystem(data) { dispatch({ type: 'SET_DESIGN_SYSTEM', payload: data }); }
  const handleSetView = useCallback((data) => {
    dispatch({ type: 'SET_VIEW', payload: data });
  }, []);  // No dependencies mean the function never changes
  
  function handleSetSelector(data) { dispatch({ type: 'SET_SELECTOR', payload: data == 'initial_state' ? initialState.selector : data }); }
  function handleSetProject(data) { dispatch({ type: 'SET_PROJECT', payload: data }); }
  function handleSetDnD(data) { dispatch({ type: 'SET_DND_STATE', payload: data }); }

  function handleMouseEnter(objId, e) { e.stopPropagation(); setHoveredObjId(objId)};
  function handleMouseLeave(objId, e) { e.stopPropagation(); setHoveredObjId(null)};
  
  const goDark = designSystem?.themes?.find(t => t.id == currentThemeId)?.is_dark
  const emptyFrame = selector?.frame?.objects?.length == 0
  
  const defaultTheme = designSystem?.themes?.find(theme => theme.id == designSystem.designSystem?.default_theme_id)
  const currentTheme = designSystem?.themes?.find(t => t.id == currentThemeId)

  const effectiveVariables = currentTheme && defaultTheme ? mergeThemeVariables(defaultTheme, currentTheme) : defaultTheme
  
  // dndState.excludeIds.length > 0 && console.log(dndState.excludeIds)
  
  const [ streaming, setStreaming ] = useState(false)
  const [ activeSticky, setActiveSticky ] = useState(false)

  function addNotification(payload) {
    console.log('hi')
    const id = uuidv1(); // Using uuid for unique id generation
    const { message, timeout, state } = payload
    setNotifications(currentNotifications => [
        ...currentNotifications,
        { id, message, timeout, state }
    ]);
  };

  const [projectViewKey, setProjectViewKey] = useState(0);
  const refreshProjectData = async (timeout = 0) => {
      if (user && user.token) {
        const token = user ? user.token : null;
        const response = await axios.get(`/api/project/${projectID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        // Dispatch the SET_PROJECT action
        dispatch({ type: 'SET_PROJECT', payload: response.data });
        setProjectViewKey(prevKey => prevKey + 1);
        // handleSetView({ ...viewRef.current, miniatures: false });
       
      }
  };
  
  const viewRef = useRef(view);  // Create a ref to track the latest view state

 
  // THEME SWITCHER MANAGED AT THIS LEVEL BECAUSE IT HAS A 
  // TIMEOUT THAT REFRESHES THUMBNAILS and we don't want that to close if themeEditor is closed
  // NEED TO ISOLATE INTO PURE FUNCTION
  const timeoutRef = useRef(null);
  const selectTheme = (themeId) => {

    
    const updatedProject = {...project, project: {...project.project, current_theme_id: themeId}};
    
    const newThemeIsDark = state.designSystem?.themes?.find(t=>t.id == themeId)?.is_dark
    handleSetView({...view, nightMode: newThemeIsDark})


    if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
    }

    // handleSetView({...view, miniatures: true});
    handleSetProject(updatedProject);
    projectsDB.update(updatedProject.project);


    timeoutRef.current = setTimeout(() => {
        projectsDB.update(updatedProject.project, true); // update with `updateThumbnails = true`
        
        // Use viewRef.current here to get the latest view state
        timeoutRef.current = setTimeout(() => {
            refreshProjectData();  
        }, 15000); // 

        console.log('hi')
    }, 5000); // 
  };

  useEffect(() => {
      return () => {
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
          }
      };
  }, []);
  
  const isAdmin = user?.isAdmin || user?.is_admin || user?.isadmin
  const defaultIconSet = currentTheme?.iconSet || 'iconoir'
  
  const dataLoaded = project && designSystem


  const folders = project?.folders?.filter(f => !f.is_archived) || []
  const pages = project?.pages?.filter(p=>!p.is_archived && folders.find(f => f.id == p.folder_id)) || []

  if (!dataLoaded) return <LoadingScreen />

  // console.log(selector.frame?.name)
  if (dataLoaded) return (

    <EditorContext.Provider value={{
      ds: state.designSystem,
      setDS: handleSetDesignSystem,
      view: state.view,
      actionsHistory,
      setView: handleSetView,
      selector: state.selector,
      setSelector: handleSetSelector, 
      currentThemeId, goDark,
      handleAction,
      project, 
      folders, 
      pages,
      assets: project?.assets || [],
       effectiveVariables, defaultTheme, currentTheme, defaultIconSet,
      components: project?.components,
      templates: project?.templates,
      set: project?.set,
      setProject: handleSetProject, 
      emptyFrame,
      streaming, setStreaming,
      dndState, setDnDState: handleSetDnD, 
      isProduction, 
      panState, setPanState, 
      setNotifications, notifications, addNotification, 
      activeSticky, setActiveSticky, 
      refreshProjectData, selectTheme, 
      isAdmin, 
    }}>
      <DSContext.Provider 
      value={{
        ds: state.designSystem,
        setDS: handleSetDesignSystem,
        effectiveVariables, defaultTheme, currentTheme, defaultIconSet,
      }}
        >
        <TemplatesContext.Provider value={{templates: project?.templates, set: project?.set}}>
          <CursorContext.Provider value={{ hoveredObjId, handleMouseEnter, handleMouseLeave }} >
            <KeyboardShortcutsProvider>


            <div className={`flex flex-row w-screen h-screen  relative bg-base-50 to-base-0 text-base-content ${view.nightMode ? 'dark' : ''}`}
            style={{zIndex: 0, maxWidth: '100vw', maxHeight: '100dvh'}}>
                  {view.overview &&
                    <Navigation />}
                  <div className='flex flex-col w-full max-w-full items-center justify-stretch h-full overflow-y-scroll relative' style={{ zIndex: 100}} > 
                  
                    <div className={`w-full max-w-full gap-3 flex px-3 items-center z-10 justify-center flex-shrink-0 text-base-500 font-semibold text-xs`} style={{height: 48}}>
                      <Header />
                    </div>
                  {view.overview ? 
                    <ProjectView refreshKey={projectViewKey} /> : 
                    <CanvasView />
                  }
                  
                  <div id="portal-root"></div>
                  </div>
                  {view.settings && 
                    <SettingsModal 
                      view={view} 
                      setView={handleSetView} 
                    />}
              </div>

      </KeyboardShortcutsProvider>
      </CursorContext.Provider>
      </TemplatesContext.Provider>
      </DSContext.Provider>
    </EditorContext.Provider>
  );
}

function LoadingScreen() {
  return (
    <div className="bg-base-0 h-screen w-screen flex items-center justify-center">
      <Spinner />
    </div>
  );
}
{/*
                <div className={`${!streaming ? 'hidden' : 'absolute absolute top-0 left-0 w-full h-full moving-shadow-box'} z-0 transition-all duration-150`}/>
                  */}

{/*triggerProjectBuilder && (
  <BuildProject
    task={data} 
    handleAction={handleAction} 
    project={project} 
    setData={setData}
    setStreaming={setStreaming}
    streaming={streaming}
    setProject={handleSetProject}
  />
)*/}