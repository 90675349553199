import { Icon } from '../';

type ImageProps = {
    src?: string;
    placeholderThumbnail?: 'default' | 'ecom_product' | 'vector_illustration',
    objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down',
    altText?: string,
    width?: '100%' | '50%' | '33%' | '25%' | '75%' | '36px' | '48px' | '64px' | '80px' | '120px',
    maxWidth?: number,
    minWidth?: number,
    height?: '100%' | '50%' | '33%' | '25%' | '75%' | '36px' | '48px' | '64px' | '80px' | '120px',
    maxHeight?: number,
    minHeight?: number,
    corners?: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full',
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    __juno?: any
}

export default function Image({
        src = null,
        placeholderThumbnail = 'default',
        objectFit = 'cover',
        altText = '',
        width = '120px',
        height = '120px',
        minWidth,
        maxWidth,
        minHeight,
        maxHeight,
        corners = 'none',
        selfAlign = 'auto',
        __juno = {}
      }: ImageProps) {
    
    const noImage = !src || !src.startsWith('https')
    const classes = `flex-shrink-0 rounded-${corners} self-${selfAlign}`
    
    const imageStyles = {
        width: width,
        height: height,
        minWidth: minWidth,
        maxWidth: maxWidth,
        minHeight: minHeight,
        maxHeight: maxHeight,
        backgroundImage: !noImage && `url(${src})`,
        backgroundSize: objectFit,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    return (<div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            aria-label={altText} 
            style={imageStyles}
            >
                {noImage && <div className={`relative w-full h-full flex items-center justify-center oveflow-hidden flex-col gap-2 text-xs border rounded-${corners}`}
                style={{ 
                    backgroundColor: 'color-mix(in srgb, var(--accent) 10%, transparent)',
                    color: 'var(--accent)',
                    borderColor: 'color-mix(in srgb, var(--accent) 30%, transparent)',
                }}>
                {<Icon icon='image' className='w-5 h-5' />}
                </div>}
            </div>
    )
}


/* ignore rest */


Image.definitions = {
    apiName: 'Image',
    displayName: 'Image',
    description: 'A versatile image component that supports custom source URLs, object fit, alternative text, and size adjustments. Includes options for corner styling and alignment. Displays a placeholder if no image source is provided.',
    ai_instructions: 'image element. will render a placeholder image if not src',
    type: 'media',
    relativeSize: 'varies',
    acceptedChildren: 'none',
    package: null,
    propDefinitions: {
        src: {
            type: 'imageURL',
            default: null, 
            displayName: 'Image',
        },
        altText: {
            type: 'string',
            default: '', 
            displayName: 'Alt Text',
        },
        objectFit: {
            type: 'oneOf',
            options: ['contain', 'cover', 'fill', 'none', 'scale-down'],
            default: 'cover', 
            displayName: 'Object Fit',
            tile: '1/2'
        },
        
        corners: {
            type: 'corners',
            options: ['none', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', 'full'],
            default: 'none', 
            displayName: 'Corners',
            tile: '1/2'
        },
        width: {
            type: 'customSize',
            options: ['100%', '50%', '33%', '25%', '75%', '36px', '48px', '64px', '80px', '120px'],
            default: '120px', 
            displayName: 'Width',
            ai_instructions: 'simply pass either % or px values, e.g. "50%" or "240px", will be used for css width property'
        },
        height: {
            type: 'customSize',
            options: ['100%', '50%', '33%', '25%', '75%', '36px', '48px', '64px', '80px', '120px'],
            default: '120px',
            displayName: 'Height', 
            ai_instructions: 'simply pass either % or px values, e.g. "50%" or "240px", will be used for css height property'
        },
        minWidth: {
            type: 'string',
            default: null,
            ai_instructions: 'simply pass either % or px values, e.g. "50%" or "240px", will be used for css height property', 
            displayName: 'Min Width', 
            tile: '1/2'
        },
        maxWidth: {
            type: 'string',
            default: null,
            ai_instructions: 'simply pass either % or px values, e.g. "50%" or "240px", will be used for css height property', 
            displayName: 'Max Width',
            tile: '1/2'
        },
        minHeight: {
            type: 'string',
            default: null,
            ai_instructions: 'simply pass either % or px values, e.g. "50%" or "240px", will be used for css height property', 
            displayName: 'Min Height',
            tile: '1/2'
        },
        maxHeight: {
            type: 'string',
            default: null,
            ai_instructions: 'simply pass either % or px values, e.g. "50%" or "240px", will be used for css height property', 
            displayName: 'Max Height',
            tile: '1/2'
        },
        selfAlign: {
            type: 'oneOf',
            options: ['auto', 'start', 'center', 'end'],
            default: 'auto'
        }
    }
}

/*
Image.propTypes = {
    src: PropTypes.string,
    placeholderThumbnail: PropTypes.oneOf(['default', 'ecom_product', 'vector_illustration']),
    objectFit: PropTypes.oneOf(['contain', 'cover', 'fill', 'none', 'scale-down']),
    altText: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.oneOf(['100%', '50%', '33%', '25%', '75%', '36px', '48px', '64px', '80px', '120px']),
        PropTypes.string
    ]),
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    height: PropTypes.oneOfType([
        PropTypes.oneOf(['100%', '50%', '33%', '25%', '75%', '36px', '48px', '64px', '80px', '120px']),
        PropTypes.string
    ]),
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    corners: PropTypes.oneOf(['none', 'sm', 'base', 'md', 'lg', 'full']),
    selfAlign: PropTypes.oneOf(['auto', 'start', 'center', 'end']),
};
 */