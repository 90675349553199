import React from 'react';
import { spacingMap } from '../helpers';

type FooterProps = {
    paddingX?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    gap?: '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',

    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | string,
    hasBorder?: boolean,
    
    alignItems?: 'start' | 'end' | 'center' | 'stretch',
    fontSize?: 'sm' | 'base' | 'md' | 'lg',
    minHeight?: number,
    width?: 'stretch' | '780px' | '960px' | '1200px' | '1440px',
    children?: React.ReactNode,
    __juno?: any,
}

export default function Footer({
        paddingX = null,
        paddingY = null,
        gap = null, 

        background = null,
        hasBorder = false,
        
        alignItems = 'stretch',
        fontSize = 'base',
        minHeight = null,
        width = 'stretch',
        children,
        __juno = {}
      }: FooterProps) {

    const bgStyles = background ? `bg-${background}` : '';
    const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content') ? 'text-base-content' : `text-base-0`
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const borderStyles = hasBorder ? 'border-t border-current-10' : '';
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const heightStyles = minHeight ? `min-h-[${minHeight}px]` : '';
    const fontSizeStyles = `text-${fontSize}`;

    const outerClasses = `w-full flex flex-col items-center relative ${bgStyles} ${borderStyles}`
    const innerClasses = `flex flex-col justify-between w-full min-h-[60px] mx-auto border-box ${fontSizeStyles} ${paddingStyles} ${gapStyles} ${heightStyles} ${alignItemsStyles} ${fontColor}` 



    return (
        <div 
        className={`${outerClasses} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        >
            <div 
            className={`${innerClasses} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            style={{maxWidth: width != 'stretch' ? width : '100%'}}>
            {children}
            </div>
        </div>
    );
}


/* ignore rest */

Footer.definitions = {
    apiName: 'Footer',
    displayName: 'Footer',
    description: 'A customizable footer section for webpages and applications, supporting various styles and child components.',
    ai_instructions: 'footer section. can only go inside Main or AppShell. do not use in tables or other elements. has flex-col property.',
    type: 'section',
    relativeSize: 'large',
    package: null,
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['stretch', '780px', '960px', '1200px','1440px'],
            displayName: 'Width',
            default: 'stretch', 
            tile: '1/2',
        },
        minHeight: {
            type: 'number',
            default: null, 
            ai_instructions: 'should not be too high', 
            displayName: 'Min Height',
            tile: '1/2',
        }, 

        background: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'none'],
            displayName: 'Background',
            default: 'none', 
            tile: '1/2',
        },
        fontSize: {
            type: 'oneOf',
            options: ['sm', 'base', 'md', 'lg'],
            default: 'base',
            displayName: 'Font Size',
            tile: '1/2',
        },
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center', 'stretch'],
            displayName: 'Align Items',
            default: 'stretch', 
            tile: '1/2',
        },
        hasBorder: {
            type: 'bool',
            displayName: 'Top Border',
            default: false, 
            ai_instructions: 'adds thin base-300 border on top'
        },
        
        
        
    }
};

/*Footer.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-50', 'base-100', 'base-200']),
        PropTypes.string]),
    width: PropTypes.oneOf(['stretch', '780px', '960px', '1200px','1440px']),
    minHeight: PropTypes.number,
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    hasBorder: PropTypes.bool,
    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    fontSize: PropTypes.oneOf(['sm', 'base', 'md', 'lg']),
    children: PropTypes.node
};*/