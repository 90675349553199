import React from 'react';
import { spacingMap } from '../helpers';

type FlexBoxProps = {
    direction?: 'flex-col' | 'flex-row',
    width?: 'full' | 'auto' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4',
    height?: 'h-full' | 'h-1/2' | 'h-1/3' | 'h-1/4' | 'h-2/3' | 'h-[integer]px' | 'h-auto',
    paddingX?: '0px' | '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    paddingY?: '0px' | '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    gap?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'base-900',
    bgOpacity?: '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100',
    hasOutline?: boolean,
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
    corners?: 'none' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | 'full',
    alignItems?: 'start' | 'center' | 'end' | 'stretch',
    justifyContent?: 'start' | 'center' | 'end' | 'between',
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'md',
    maxWidth?: number,
    minWidth?: number,
    maxHeight?: number,
    minHeight?: number,
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    display?: boolean,
    bgImageSrc?: string,
    fontColor?: 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'base-500' | 'base-700' | 'primary' | 'accent' | 'base-900' | 'base-content' | 'auto' | 
        'success' | 'error' | 'warning' | 'info' | 'success-content' | 'error-content' | 'warning-content' | 'info-content',
    children: React.ReactNode,
    __juno?: any
}

export default function FlexBox({
        direction = "flex-col",
        width = "full",
        height = "h-auto",
        paddingX,
        paddingY,
        background,
        bgOpacity,
        hasOutline,
        flexWrap = "nowrap",
        corners = "none",
        gap,
        alignItems = "start",
        justifyContent = "start",
        textSize = "auto",
        maxWidth,
        minWidth,
        maxHeight,
        minHeight,
        selfAlign = "auto",
        display = true,
        bgImageSrc,
        fontColor,
        children,
        __juno = {}
      }: FlexBoxProps) {

    const bgStyles = background ? `bg-${background}` : '';
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const widthStyles = width ? `w-${width}` : '';
    const heightStyles = `${height ? height : ''} ${maxHeight ? `max-h-[${maxHeight}px]` : ''} ${minHeight ? `min-h-[${minHeight}px]` : ''}`;
    const fontSize = textSize != 'auto' ? 'text-'+textSize : '';
    const borderStyles = hasOutline ? 'ring-1 ring-inset ring-base-200' : '';
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const wrapStyles = flexWrap ? `flex-${flexWrap}` : '';
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    const inheritFontStyle = (!background) ? '' : (background?.startsWith('base') && background != 'base-900') ? `text-base-content` : `text-base-0`;
    const fontStyles = (fontColor == 'auto' || !fontColor) ? inheritFontStyle : `text-${fontColor}`

    const classes = `flex ${direction} ${wrapStyles} ${widthStyles} ${selfAlign ? 'self-'+selfAlign : ''} ${fontStyles} ${fontSize} ${bgStyles} ${borderStyles} ${gapStyles} ${paddingStyles} ${cornerStyles} ${alignItemsStyles} ${justifyContentStyles} ${heightStyles}`
    
    const inLineStyles = {
        background: bgImageSrc && `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${bgImageSrc}) no-repeat center center / cover`, 
        backgroundColor: bgOpacity ? `color-mix(in srgb, var(--${background}) ${bgOpacity}%, transparent)` : null, 
        maxWidth: maxWidth, 
        minWidth: minWidth,
        maxHeight: maxHeight,
        minHeight: minHeight
    };

    const filteredInLineStyles = Object.fromEntries(
        Object.entries(inLineStyles).filter(([_, value]) => value != null)
    );

    if (display) return (
        <div 
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            style={Object.keys(filteredInLineStyles).length > 0 ? filteredInLineStyles : undefined}
        >
            {children}
        </div>
    );
}




/* ignore rest */

FlexBox.definitions = {
    apiName: "FlexBox",
    displayName: "FlexBox",
    description: "A versatile FlexBox component for structuring layout and content, with a wide range of styling options.",
    ai_instructions: 'works a div flexbox container. customizable. should be used generously to group / wrap components together',
    type: 'container',
    relativeSize: 'various',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    status: 'stable',
    package: null, 
    propDefinitions: {
        direction: {
            type: "direction",
            options: ["flex-col", "flex-row"],
            displayName: "Direction",
            default: "flex-col", 
            tile: '1/2',
            required: true
        },
        flexWrap: {
            type: "oneOf",
            options: ["nowrap", "wrap", "wrap-reverse"],
            displayName: "Flex Wrap",
            default: "nowrap", 
            tile: '1/2',
            required: true
        },   
        alignItems: {
            type: "alignItems",
            displayName: "Align Items",
            options: ["start", "center", "end", "stretch"],
            default: "start", 
            required: true,
        },
        justifyContent: {
            type: "justifyContent",
            displayName: "Justify Content",
            options: ["start", "center", "end", "between"],
            default: "start",
            required: true, 
        },
        background: {
            type: "colors",
            options: ["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900",],
            displayName: "Background",
            default: null,
            tile: '1/2',
        },
        bgOpacity: {
            type: "oneOf",
            displayName: "Opacity (bg)",
            options: ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
            default: '100',
            tile: '1/2',
        },
        fontColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-500', 'base-700', 'primary', 'accent', 'base-900', 'base-content', 'auto', 
            'success', 'error', 'warning', 'info', 'success-content', 'error-content', 'warning-content', 'info-content'],
            displayName: 'Font Color',
            default: null,
            tile: '1/2',
        },
        textSize: {
            type: "oneOf",
            options: ["auto", 'xs', "sm", "base", "md"],
            default: "auto", 
            tile: '1/2',
        },
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
             
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl', "full"],
            displayName: "Corners",
            default: null, 
            tile: '1/2',
        },
        gap: {
            type: "gap",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        width: {
            type: "oneOf",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4'],
            displayName: "Width",
            default: "full", 
            tile: '1/2',
        },
        height: {
            type: 'oneOf',
            options: ['h-full', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px', 'h-auto'],
            default: 'h-auto',
            displayName: "Height",
            tile: '1/2', 
            ai_instructions: 'for custom height use "h-[integer]px" syntax but replace integer with pixel height, e.g. "h-[200px]", i.e. follow tailwindcss utilities approach'
        },
        minWidth: {
            type: "number",
            default: null, 
            displayName: "Min Width",
            tile: '1/2',
        },
        minHeight: {
            type: "number",
            default: null,
            displayName: "Min Height",
            tile: '1/2',
        },

        maxWidth: {
            type: "number",
            default: null, 
            displayName: "Max Width",
            tile: '1/2',
        },

        maxHeight: {
            type: "number",
            default: null, 
            displayName: "Max Height",
            tile: '1/2',
        }
        ,selfAlign: {
            type: 'oneOf',
            displayName: 'Self Align',
            options: ['auto', 'start', 'center', 'end'],
            default: 'auto'
        }, 
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
            tile: '1/2',
        },
        
        display: {
            type: "bool",
            displayName: "Display",
            default: true
        },
        hide_from_ai: {
            type: "bool",
            displayName: "Hide contents from AI",
            ai_instructions: 'there are things inside, but they are hidden from ai not to confuse context',
            default: false
        },
        bgImageSrc: {
            type: 'imageURL',
            displayName: 'Image',
            ai_instructions: 'adds background image as cover, use rarely, make sure font color of the objects inside is readable',
            default: null
        },
        

    }
};

/*FlexBox.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900" ]),
        PropTypes.string]),
    bgOpacity: PropTypes.oneOf(["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"]),
    direction: PropTypes.oneOf(["flex-col", "flex-row"]),
    width: PropTypes.oneOf(["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4']),
    height: PropTypes.oneOf(['h-full', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px', 'h-auto']),
    
    paddingX: PropTypes.oneOf(["0px", "2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["0px", "2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    gap: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    
    hasOutline: PropTypes.bool,
    flexWrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),
    corners: PropTypes.oneOf(["none", "sm", 'base', "md", "lg", 'xl', '2xl', "full"]),
    
    alignItems: PropTypes.oneOf(["start", "center", "end", "stretch"]),
    justifyContent: PropTypes.oneOf(["start", "center", "end", "between"]),
    textSize: PropTypes.oneOf(["auto", 'xs', "sm", "base", "md"]),
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    selfAlign: PropTypes.oneOf(['auto', 'start', 'center', 'end']),
    display: PropTypes.bool,
    hide_from_ai: PropTypes.bool,
    bgImageSrc: PropTypes.string,
    fontColor: PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'base-300', 'base-500', 'base-700', 'primary', 'accent', 'base-900', 'base-content', 'auto', 
        'success', 'error', 'warning', 'info', 'success-content', 'error-content', 'warning-content', 'info-content']),
    children: PropTypes.node
};*/