import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import UserContext from "./UserContext";
import { auth } from "./utilities/firebaseConfig";
import "./styles/App.css";

// PAGES
import LoginPage from "./pages/auth/LoginPage";
import RegisterPage from "./pages/auth/RegisterPage";
import EditorPage from "./pages/editor/editor";
import TestPage from "./pages/testpage";
import ForgotPassword from "./pages/auth/ForgotPassword";
import NotFoundPage from "./pages/misc/NotFoundPage";
import ShareProject from "./pages/share/ShareProject";
import EditorBeta from "./pages/beta/BetaPage";
import LogoutPage from "./pages/auth/LogOutPage";
import ShareFrame from "./pages/share/ShareFrame";
import TakeScreenshot from "./pages/share/TakeScreenshot";
import AdminPage from "./pages/admin/AdminPage";
import ShareFeature from "./pages/share/ShareFeature";
import ShareFrameFull from "./pages/share/ShareFrame_full";
import Spinner from "./components/Spinner";
import IconsPage from "./pages/tests/IconsPage";
import ProjectsPage from "./pages/home/ProjectsPage";
import StudioPage from "./pages/studio/StudioPage";
import LimitedSignUp from "./pages/auth/LimitedSignUp";
import ProtocolApp from "protocol/ProtocolApp";

export default function App() {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Unified function to update token in both local storage and user state
  function updateToken(newToken) {
    localStorage.setItem("token", newToken);
    setUser(prevUser => {
      return { ...prevUser, token: newToken };
    });
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setIsLoading(true); // Set loading true to show a loading state until all processes are complete
        try {
          const token = await firebaseUser.getIdToken();
          updateToken(token); // Update token in localStorage and state
  
          // Fetch the user's data from your backend using the Firebase UID
          const response = await fetch('/auth/user', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid: firebaseUser.uid })
          });
          
          const userData = await response.json();
          
          if (userData && userData.id && userData.email) {
            setUser({
              id: userData.id,
              firebaseId: firebaseUser.uid,
              email: userData.email,
              firstname: userData.firstname || null,
              lastname: userData.lastname || null,
              isGuest: userData.is_guest || false,
              currentMonthUsage: userData.currentMonthUsage || { total_cost: null, total_tokens: null },
              isPro: userData.ispro || false,
              isAdmin: userData.isadmin || false,
              usagesoftlimit: userData.usagesoftlimit,
              token: token
            });
  
            setIsAuthenticated(true);
            setIsGuestUser(userData.is_guest || false);
  
          } else {
            // console.error("User data is incomplete:", userData);
          }
        } catch (error) {
          console.error("Error during authentication or data fetching:", error);
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        // console.log('No Firebase user authenticated.');
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      }
      setIsLoading(false);
    });
  
    return () => unsubscribe(); // Clean up the subscription
  }, []);
  

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const currentUser = auth.currentUser;
        if (currentUser) {
          const newToken = await currentUser.getIdToken(true);
          updateToken(newToken); // Update token using the unified function
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const checkUserAuthState = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const newToken = await currentUser.getIdToken(true);
        updateToken(newToken); // Update token using the unified function
      }
    };

    window.addEventListener('focus', checkUserAuthState);
    return () => {
      window.removeEventListener('focus', checkUserAuthState);
    };
  }, []);
  
  // console.log('user',user)
  function handleLogout() {
    auth
      .signOut()
      .then(() => {
        setUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem("token");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  }
  const isProduction = true // window.location.hostname === "www.junodesign.app" || window.location.hostname === "localhost";

  const [ data, setData ] = useState(null)
  const isAdmin = user?.id == '994ce318-d3fb-11ed-a933-ea12fce6e513'
  
  const getUserToken = async () => {
    if (user) {
      return user.token;
    }
    return null;
  }
  
  
  /*useEffect(() => {
    // On page load or when changing themes
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);*/
  //<Route path="/register" element={<RegisterPage />} />
  
  return (
    <UserContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, isGuestUser, setIsGuestUser, isProduction, handleLogout }}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Routes>
          
          {/* Unprotected routes */}
          <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/register" element={<LimitedSignUp />} />
          <Route path="/register-beta" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/request-access" element={<EditorBeta />} />
          <Route path="/teaser" element={<Navigate to="/studio" />} />
          <Route path="/studio" element={<StudioPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={isAuthenticated ? <Navigate to="/projects" /> : <Navigate to="/studio" />} />

          {/* Concepts & Tests */}
          <Route path="/testing-tings" element={<TestPage />} />
          <Route path="/test-icon-map" element={<IconsPage />} />

          {/* Client Projects */}
          <Route path="/apps/protocol" element={<ProtocolApp />} />
          
          {/* Admin */}
          <Route path="/apiusage" element={isAdmin ? <AdminPage user={user} /> : null}/>
          
          {/* Protected routes */}
          <Route path="/projects" element={!isAuthenticated ? (<Navigate to="/login" />) : (<ProjectsPage handleLogout={handleLogout} data={data} setData={setData}/>)}/>
          <Route path="/project/:projectID" element={isAuthenticated ? (<EditorPage handleLogout={handleLogout} data={data} setData={setData}/>) : (<Navigate to="/login" />)} />
          <Route path="/project/:projectID/canvas" element={isAuthenticated ? (<EditorPage handleLogout={handleLogout} data={data} setData={setData}/>) : (<Navigate to="/login" />)} />
          <Route path="/project/templates" element={isAuthenticated ? (<Navigate to='/project/e02f62c2-4607-11ef-8559-16ffc445b83b' />) : (<Navigate to="/login" />)} />
          <Route path="/project/elements" element={isAuthenticated ? (<Navigate to='/project/19122f54-4859-11ef-b7fd-16ffc445b83b' />) : (<Navigate to="/login" />)} />
          <Route path="/share/project/:projectID" element={<ShareProject />} />
          <Route path="/share/feature/:pageId" element={<ShareFeature />} />
          <Route path="/share/frame/:frameId" element={<ShareFrame />} />
          
          <Route path="/share/frame_full/:frameId" element={<ShareFrameFull />} />
          <Route path="/screenshot/:frameId" element={<TakeScreenshot />} />

        </Routes>)}
    </UserContext.Provider>
  );
}

{/*
<Route path="/beta" element={<EditorBeta handleLogout={handleLogout} />} />
<Route path="/editor-beta" element={<Navigate to="/beta" replace />} />*/}


function LoadingScreen() {
  return (
    <div className="bg-white h-screen w-screen flex items-center justify-center">
      <Spinner />
    </div>
  );
}