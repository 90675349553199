import { useContext, useState } from "react";
import ThemeDemo from "./ThemeDemo";
import ThemePreview from "./ThemePreview";
import { EditorContext } from "../../../EditorContext";
import * as IconoirIcons from 'iconoir-react';
import UserContext from "../../../../../UserContext";
import ThemeEditor from "./builder/ThemeEditor";
import { deleteThemeInDB, saveThemeToDB, updateThemeInDB } from "../../../../../api/ThemesAPI";
import { buildThemeObject, makeDefaultTheme } from "./builder/helpers";

export default function Themes({themes, appliedTheme}) {
    const {ds, currentTheme, setDS, defaultTheme, selectTheme} = useContext(EditorContext)
    const { user } = useContext(UserContext);
    
    const [state, setState] = useState('list')
    
    function createTheme() {
        let newTheme = buildThemeObject(makeDefaultTheme()) // DONT KILL ME FOR THIS => it's a quick mashup from older approach
        newTheme = {...newTheme, ownerId: user?.id}
        saveThemeToDB(newTheme, user?.token)  // add to DB
        setDS({...ds, themes: [...ds.themes, newTheme]}) // add to state
        selectTheme(newTheme.id) // select new theme
        setState('editor')
    }

    function updateTheme(theme) {
        updateThemeInDB(theme, user?.token)
        setDS({...ds, themes: ds.themes.map(t=> t.id == theme.id ? theme : t)})
        selectTheme(theme.id)

    }

    function deleteTheme(themeId) {
        deleteThemeInDB(themeId, user?.token)
        selectTheme(defaultTheme?.id) // fallback to default theme
        setDS({...ds, themes: ds.themes.filter(t=> t.id != themeId)}) 
    }

    function editTheme(themeId) {
        selectTheme(themeId)
        setState('editor')
    }

    const tileHeight = 60;
    return (
        <div className="w-full flex flex-row gap-3 h-full"
            style={{ height: '100%', maxHeight: '100%', minHeight: '100%'}}>
          <div className={`flex flex-col w-1/4 gap-2 transition-all duration-100
          ${state == 'list' ? 'overflow-hidden min-w-[320px]' : 'min-w-[400px]'}`}
          
           style={{ height: '100%', maxHeight: '100%', minHeight: '100%'}}>
                
                
                {(state == 'builder' || state == 'editor') &&
                <ThemeEditor
                    theme={currentTheme} 
                    onSave={(theme) => updateTheme(theme)}
                    onClose={()=>setState('list')}
                    onDelete={deleteTheme}
                    />
                }
                
                {state == 'list' && 
                <div className="flex flex-col gap-2 items-stretch overflow-y-scroll px-3 py-2">
                {themes
                .sort((a, b) => a.display_name.localeCompare(b.display_name))
                .sort((a, b) => b.is_default - a.is_default)
                .map(theme=>
                <ThemePreview
                    key={theme.id} 
                    theme={theme} 
                    isDefault={false} //theme.is_default}
                    isSelected={currentTheme?.id == theme.id}
                    onSelect={selectTheme}
                    onEdit={editTheme}
                    tileHeight={tileHeight}
                    borderRadius={appliedTheme?.variables?.find(v=>v.api_name == 'border-radius-lg')?.value || '0.5em'}
                    borderColor={appliedTheme?.variables?.find(v=>v.api_name == 'base-100')?.value}
                />)}
                
                <AddButton 
                    onClick={createTheme}
                    tileHeight={tileHeight}
                />
                </div>}
                
            </div>
            <div className="flex flex-col flex-grow h-full overflow-hidden py-2">
                    <ThemeDemo 
                    theme={currentTheme}
                    assets={ds?.assets || []}
                    /> 
            </div>  
        </div>
    )
}

function AddButton({onClick, tileHeight}) {
    return (
    <div className={`w-full flex flew-row items-center mb-1 justify-start font-medium text-sm gap-2 justify-center transition-all rounded-lg 
                    overflow-hidden hover:scale-105 relative group 
                    border border-slate-200 hover:border-slate-300  !border-base-200 hover:!border-base-300
                    bg-slate-100 !bg-base-100 
                    text-slate-400 hover:text-slate-500 !text-base-400 hover:!text-base-500
                    `}
                    onClick={onClick}
                    style={{
                        height: tileHeight,
                        minHeight: tileHeight,
                        
                    }}
                    >
                    <IconoirIcons.Plus height={24} width={24} style={{strokeWidth: 1.2}}/>
                    <div className="whitespace-nowrap font-normal">Add new</div>
            </div>
    )
}





            
