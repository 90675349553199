import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { EditorContext } from './EditorContext';
import FlowDisplay from './overview/insideproject/FlowDisplay';
import FolderDisplay from './overview/insideproject/FolderDisplay';
import ProjectDisplay from './overview/insideproject/ProjectDisplay';
import { convertThemeVariablesToStyles } from "./canvas/helpers";
import Themes from './overview/designsystem/themes/Themes';
import Install from './overview/designsystem/install/Install';
import UIKitDisplay from './overview/designsystem/uikit/UIKit';
import Brand from './overview/designsystem/brandkit/Brand';
import { NavArrowRight } from 'iconoir-react';
import OverviewHeader from './overview/header/OverviewHeader';

export default function ProjectView({refreshKey}) {
  const { selector, view, ds, project, currentTheme, defaultTheme } = useContext(EditorContext);
  
  const designSystemGroup = ['designSystem', 'brand', 'api_docs', 'ui_kit']
  
  const themes = ds?.themes;

  const appliedTheme = currentTheme || defaultTheme; 
  const themeStyles = convertThemeVariablesToStyles(appliedTheme?.variables || []);

  const tab = view.inOverview;
  const projectSelector = (view.inOverview == 'project' && !selector.folder) ? 'project' : 
  view.inOverview == 'folders' ? view.showAllFolders ? 'folder' : 'flow' : ''  
  
  return (
    
    <div className='flex flex-col w-full max-w-screen items-center justify-stretch h-full overflow-y-scroll relative' style={{ zIndex: 100}} > 
        <div className="relative flex flex-col gap-2 pt-8 pb-2 px-4 pl-1 transition-all rounded-md
          text-base-content ring-[0.5px] ring-inset ring-base-200 bg-base-0 box-border h-full my-1"
          style={{ 
            overflow: 'hidden', 
            width: 'calc(100% - 16px)',
            maxWidth: 'calc(100% - 16px)',
            maxHeight: 'calc(100% - 16px)',
            ...(tab === 'designSystem' ? {...themeStyles} : {})
          }}>
            <OverviewHeader tab={tab} />

        {designSystemGroup.includes(view.inOverview) 
          ? 
          <>
          <div className="flex px-3 py-3 overflow-hidden h-full w-full">
            {tab === 'designSystem' && 
              <Themes 
                themes={themes} 
                appliedTheme={appliedTheme} 
                />}
            {tab === 'api_docs' && <Install appliedTheme={appliedTheme}/>}
            {tab === 'ui_kit' && <UIKitDisplay />}
            {tab === 'brand' && <Brand />}
          </div>
          </>
          : 
          <>
          {projectSelector === 'project' && <ProjectDisplay refreshKey={refreshKey}/>}
          {(projectSelector === 'folder' ||projectSelector === 'flow')&& <FolderDisplay refreshKey={refreshKey}/>}
          {/*projectSelector === 'flow' && <FlowDisplay refreshKey={refreshKey}/>*/}
          </>
          }
        </div>
    </div>
    

  );
  }

function BreadCrumbs({selector}) {
  return (
    <div className="flex flex-row gap-2 text-xs text-base-content items-center">
      
      {selector.page && <>
      <NavArrowRight width={12} height={12} />
      {selector.page?.name}
      </>}
    </div>
  )
}