import { spacingMap } from '../helpers';
// need to add truncate and wrapping controls

type Props = {
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl',
    textColor?: 'primary' | 'primary-content' | 'accent' | 'accent-content' | 'base-0' | 'base-50' | 'base-100' | 'base-content' | 'base-500' | 'base-700' | 'base-900' | 'success-content' | 'warning-content' | 'error-content' | 'info-content' | string,
    marginBottom?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    marginTop?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    lineHeight?: 'auto' | 'none' | 'tight' | 'normal' | 'relaxed' | 'loose',
    text?: string,
    fontWeight?: 'auto' | 'hairline' | 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black',
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify',
    __juno?: any
}

export default function Text({
        textSize = 'auto',
        textColor,
        marginBottom,
        marginTop,
        lineHeight = 'auto',
        text = 'Text..',
        fontWeight = 'auto',
        textAlign = 'left',
        __juno = {}
      }: Props) {

    const textSizeStyles = textSize !== 'auto' ? `text-${textSize}` : '';
    const textColorStyles = (textColor == 'none' || !textColor) ? `` : `text-${textColor}`
    const marginBottomStyles = marginBottom ? `mb-${spacingMap[marginBottom]}` : '';
    const marginTopStyles = marginTop ? `mt-${spacingMap[marginTop]}` : '';
    
    const lineHeightStyles = lineHeight !== 'auto' ? `leading-${lineHeight}` : '';
    const fontWeightStyles = fontWeight !== 'auto' ? `font-${fontWeight}` : '';
    const textAlignStyles = textAlign ? `text-${textAlign}` : '';

    const classes = `inline-flex ${textSizeStyles} ${textColorStyles} ${marginBottomStyles} ${marginTopStyles} ${lineHeightStyles} ${fontWeightStyles} ${textAlignStyles}`
    
    return (
        <span
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        style={{whiteSpace: 'pre-wrap'}}>
        {text}
        </span>
    );
}


/* ignore rest */


Text.definitions = {
    apiName: 'Text',
    displayName: 'Text',
    description: 'A text component with editable content and customizable text size, color, margins, line height, font weight, and text alignment. Allows for dynamic styling and is interactive for direct content editing.',
    ai_instructions: 'represents <span> element. useful for short text. for longer copy use Paragraph component',
    type: 'typography',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    package: null,
    propDefinitions: {
        text: {
            type: 'string',
            default: 'Text..', 
            displayName: 'Text',
            editable: true,
        },
        textColor: {
            type: 'colors',
            displayName: 'Color',
            options: ['primary', 'primary-content', 'accent', 'accent-content', 'base-0', 'base-50', 'base-100', 'base-content', 'base-500', 'base-700', 'base-900'
            ,'success-content', 'warning-content', 'error-content', 'info-content'],
            default: null,
            miniEditor: true,
        },
        textSize: {
            type: 'oneOf',
            displayName: 'Text Size',
            options: ['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl'],
            default: 'auto', 
            tile: '1/2'
        },
        fontWeight: {
            type: 'oneOf',
            options: ['auto', 'hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black'],
            displayName: 'Font Weight',
            default: 'auto', 
            tile: '1/2'
        },
        lineHeight: {
            type: 'oneOf',
            options: ['auto', 'none', 'tight', 'normal', 'relaxed', 'loose'],
            displayName: 'Line Height',
            default: 'auto', 
            tile: '1/2'
        },
        textAlign: {
            type: 'oneOf',
            displayName: 'Text Alignment',
            options: ['auto', 'left', 'right', 'center', 'justify'],
            default: 'left', 
            tile: '1/2'
        },
        marginTop: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Top Margin',
            default: null,
            tile: '1/2'
        },
        marginBottom: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Bottom Margin',
            default: null,
            tile: '1/2'
        },
        
    }
};



/*Text.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.oneOfType([
        PropTypes.oneOf(['primary', 'primary-content', 'accent', 'accent-content', 'base-0', 'base-50', 'base-100', 'base-content', 'base-500', 'base-700', 'base-900','success-content', 'warning-content', 'error-content', 'info-content']),
        PropTypes.string]),
    textSize: PropTypes.oneOf(['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl']),
    lineHeight: PropTypes.oneOf(['auto', 'none', 'tight', 'normal', 'relaxed', 'loose']),
    textAlign: PropTypes.oneOf(['auto', 'left', 'right', 'center', 'justify']),
    fontWeight: PropTypes.oneOf(['auto', 'hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
    children: PropTypes.node, 
    marginTop: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    marginBottom: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
};
*/