import * as SampleLogos from '../../assets/brand/index';

type LogoProps = {
    type?: 'logo' | 'symbol',
    size?: '20px' | '24px' | '28px' | '36px' | '40px' | '48px' | '60px' | '96px',
    color?: 'normal' | 'inverted',
    customWidth?: number,
    customHeight?: number,
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    assets?: any,
    __juno?: any
}

/* TODO needs auto swap for inverted logo when dark mode is enabled */

export default function Logo({
        type = 'symbol',
        size = '28px',
        customWidth = null,
        customHeight = null,
        selfAlign = 'auto',
        color = 'normal',
        assets,
        __juno = {},
      }: LogoProps) {
    
    const useLogo = 'sample' 
    
    const sample_symbol = SampleLogos[useLogo+'_symbol']
    const sample_logo = SampleLogos[useLogo]
    const sample_symbol_inverted = SampleLogos[useLogo+'_symbol_inverted']
    const sample_logo_inverted = SampleLogos[useLogo+'_inverted']

    const asset_logo = getComputedStyle(document.documentElement).getPropertyValue('--logo').trim().replace(/^["']|["']$/g, "");
    const asset_logo_inverted = getComputedStyle(document.documentElement).getPropertyValue('--logo_inverted').trim().replace(/^["']|["']$/g, "");
    const asset_symbol = getComputedStyle(document.documentElement).getPropertyValue('--symbol').trim().replace(/^["']|["']$/g, "");
    const asset_symbol_inverted = getComputedStyle(document.documentElement).getPropertyValue('--symbol_inverted').trim().replace(/^["']|["']$/g, "");
    
    // on Canvas /* remove line */
    const symbolAsset = assets?.find(asset => asset.api_name == 'symbol')?.file_url || sample_symbol /* remove line */
    const symbolInvertedAsset = assets?.find(asset => asset.api_name == 'symbol_inverted')?.file_url || sample_symbol_inverted /* remove line */
    const logoAsset = assets?.find(asset => asset.api_name == 'logo')?.file_url || sample_logo /* remove line */
    const logoInvertedAsset = assets?.find(asset => asset.api_name == 'logo_inverted')?.file_url || sample_logo_inverted /* remove line */
    
    // Exported /* remove line */
    /* replace to 'const symbolAsset = assets?.find(asset => asset.api_name == 'symbol')?.file_url || asset_symbol' */
    /* replace to 'const symbolInvertedAsset = assets?.find(asset => asset.api_name == 'symbol_inverted')?.file_url || asset_symbol_inverted' */
    /* replace to 'const logoAsset = assets?.find(asset => asset.api_name == 'logo')?.file_url || asset_logo' */
    /* replace to 'const logoInvertedAsset = assets?.find(asset => asset.api_name == 'logo_inverted')?.file_url || asset_logo_inverted' */

    const imageOptions = [
        {option: 'symbol', url: color != 'inverted' ? symbolAsset : symbolInvertedAsset}, 
        {option: 'logo', url: color != 'inverted' ? logoAsset : logoInvertedAsset}
    ]
    const fallbackOptions = [
        {option: 'symbol', url: color != 'inverted' ? sample_symbol : sample_symbol_inverted},
        {option: 'logo', url: color != 'inverted' ? sample_logo : sample_logo_inverted}
    ]
    
    const imageURL = imageOptions.find(option => option.option === type)?.url || null;
    const fallbackLogo = fallbackOptions.find(option => option.option === type)?.url || null;
    
    const classes = `flex-shrink-0 self-${selfAlign}` 
    
    const wrapperInlineStyles = {
        width: customWidth ? customWidth : type == 'symbol' && size,
        height: customHeight || size,
        objectFit: 'contain' as React.CSSProperties['objectFit'], 
    }

    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            style={wrapperInlineStyles}
            > 
            
            <img 
                src={imageURL || fallbackLogo}
                style={wrapperInlineStyles} 
                draggable={false} 
                onError={(e) => { e.currentTarget.src = fallbackLogo}}
                
            />
        </div>   
    )
}

/* ignore rest */


Logo.definitions = {
    apiName: 'Logo',
    displayName: 'Logo',
    description: 'A component for displaying a logo with options to choose between a symbol and a full logo. It supports customization of size, width, height, and alignment. The logo automatically adjusts for dark or light themes.',
    ai_instructions: 'can be symbol or full logo. symbol is square, logo is elongated horizontally. automaticlaly adapts to dark and light themes ',
    type: 'media',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        type: {
            type: 'oneOf',
            options: ['logo', 'symbol'],
            displayName: 'Type',
            default: 'symbol',
            tile: '1/2'
            
        },
        selfAlign: {
            type: 'oneOf',
            options: ['auto', 'start', 'center', 'end'],
            displayName: 'Self Align',
            default: 'auto', 
            tile: '1/2'
        },
        size: {
            type: 'oneOf',
            options: ['20px', '24px', '28px', '36px', '40px', '48px', '60px', '96px'],
            displayName: 'Size (height)',
            default: '28px', 
            tile: '1/2'
        },
        color: {
            type: 'oneOf',
            options: ['normal', 'inverted'],
            displayName: 'Color',
            ai_instructions: 'normal is usually dark on light background and inverted is used on dark background',
            default: 'normal', 
            tile: '1/2'
        },
        customWidth: {
            type: 'number',
            displayName: 'Custom Width',
            default: null, 
            tile: '1/2'
        },
        customHeight: {
            type: 'number',
            displayName: 'Custom Height',
            default: null, 
            tile: '1/2'
        },
    }
}


// Import the SVG files
// import sample_symbol from '../assets/brand/symbol.svg'; 
// mport sample_logo from '../assets/brand/logo.svg'; 
// import sample_symbol_inverted from '../assets/brand/symbol.svg';
// import sample_logo_inverted from '../assets/brand/logo_inverted.svg';

/*
import sample_symbol from '../assets/brand/hellodoc/hellodoc_sym.svg'; 
import sample_logo from '../assets/brand/hellodoc/hellodoc.svg';
import sample_symbol_inverted from '../assets/brand/hellodoc/hellodoc_sym.svg'; 
import sample_logo_inverted from '../assets/brand/hellodoc/hellodoc.svg';
*/