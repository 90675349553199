import React, { useState, useRef, useContext, useEffect } from 'react';
import * as IconoirIcons from 'iconoir-react';
import { EditorContext } from "../../EditorContext";
import { SegmentedSwitch } from '../../../../ui-kit/exports/react';

export default function ModeSwitch() {
    const {view, setView, selector, setSelector, streaming, activeSticky} = useContext(EditorContext);
    

    function handleSelectView(value) {
      let rightSide = view.rightSide
      
      if (value == 'eye' && (rightSide == 'editor' || rightSide == 'assets' || rightSide == 'elements')) {
        rightSide = null
      }
      console.log('rightSide', rightSide)
      setView({...view, mode: value, rightSide})
      setSelector({...selector, object: null, objectRef: null}) // clear selector

    }
    const currentValue = view.mode
    const options = [
      {value: 'editor', 
      icon: 'edit',
      // label: 'Edit'
      },
      {value: 'eye', 
      icon: 'play',
      // label: 'Preview'
      },
    ]    
    

    return (
      <div className='relative w-[108px] flex-shrink-0'>
      <SegmentedSwitch 
        size={'small'}
        options={options}
        width={'full'}  
        value={currentValue}
        bgColor={'base-100'}
        onChange={(value)=>handleSelectView(value)}
      />

      </div>
      );
  }

