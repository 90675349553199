import { spacingMap } from '../helpers'; 
// need to add truncate and wrapping controls

type Props = {
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl',
    textColor?: 'none' | 'primary' | 'primary-content' | 'accent' | 'accent-content' | 'base-0' | 'base-50' | 'base-100' | 'base-content' | 'base-500' | 'base-700' | 'base-900' | 'success-content' | 'warning-content' | 'error-content' | 'info-content' | string,
    marginBottom?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    marginTop?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    lineHeight?: 'auto' | 'none' | 'tight' | 'normal' | 'relaxed' | 'loose',
    text?: string,
    fontWeight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black',
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify',
    alignSelf?: 'auto' | 'start' | 'end' | 'center' | 'stretch',
    __juno?: any
}

export default function Heading({
        textSize = '2xl',
        textColor = null,
        marginBottom = null, 
        marginTop = null, 
        lineHeight = 'auto',
        text = 'Heading',
        fontWeight = 'semibold',
        textAlign = 'left',
        alignSelf = 'auto',
        __juno = {}
      }: Props) {
    
    const textSizeStyles = `text-${textSize}`;
    const textColorStyles = (textColor == 'none' || !textColor) ? `` : `text-${textColor}`
    
    const marginBottomStyles = marginBottom ? `mb-${spacingMap[marginBottom]}` : '';
    const marginTopStyles = marginTop ? `mt-${spacingMap[marginTop]}` : '';
    
    const lineHeightStyles = lineHeight != 'auto' ? `leading-${lineHeight}` : '';
    const fontWeightStyles = `font-${fontWeight}`
    const textAlignStyles = textAlign != 'auto' ? `text-${textAlign}` : '';
    const alignSelfStyles = alignSelf != 'auto' ? `self-${alignSelf}` : '';

    const classes = `text-ellipsis ${textSizeStyles} ${textColorStyles} ${marginBottomStyles} ${marginTopStyles} ${lineHeightStyles} ${fontWeightStyles} ${textAlignStyles} ${alignSelfStyles}`

    return (
        <h1
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        style={{whiteSpace: 'pre-wrap'}} 
        >
        {text}
        </h1>
    );
}



/* ignore rest */ 

Heading.definitions = {
    apiName: 'Heading',
    displayName: 'Heading',
    description: 'A heading component with customizable text size, color, margins, line height, font weight, text alignment, and self-alignment. Supports dynamic styling and is editable on double-click.',
    ai_instructions: 'heading to represent h1, h2, etc.',
    type: 'typography',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        text: {
            type: 'string',
            displayName: 'Text',
            editable: true,
            ai_instructions: 'keep headings concise, 20 characters or less',
            default: 'Heading', 
        },
        
        textColor: {
            type: 'colors',
            options: ['none', 'primary', 'primary-content', 'accent', 'accent-content', 'base-content', 
            'info-content', 'base-500', 'base-700', 'base-900'],
            displayName: 'Color',
            default: null,
        },
        textSize: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl'],
            displayName: 'Text Size',
            default: '2xl', 
            miniEditor: 'textSize',
            tile: '1/2'
        },

        fontWeight: {
            type: 'oneOf',
            options: ['light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black'],
            displayName: 'Font Weight',
            default: 'semibold', 
            tile: '1/2'
        },

        textAlign: {
            type: 'oneOf',
            options: ['auto', 'left', 'right', 'center', 'justify'],
            default: 'left', 
            tile: '1/2'
        },
        alignSelf: {
            type: 'oneOf',
            options: ['auto', 'start', 'end', 'center', 'stretch'],
            default: 'auto', 
            ai_instructions: 'only change from auto when you want heading to have its own alignment', 
            tile: '1/2'
        },
        marginTop: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Top Margin',
            default: null,
            tile: '1/2'
        },
        marginBottom: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Bottom Margin',
            default: null,
            tile: '1/2'
        },
        lineHeight: {
            type: 'oneOf',
            options: ['auto', 'none', 'tight', 'normal', 'relaxed', 'loose'],
            displayName: 'Line Height',
            default: 'auto', 
            tile: '1/2'
        }
    }
};

/*Heading.propTypes = {
    textColor: PropTypes.oneOfType([
        PropTypes.oneOf(['primary', 'primary-content', 'accent', 'accent-content', 'base-0', 'base-50', 'base-100', 'base-content', 'base-500', 'base-700', 'base-900','success-content', 'warning-content', 'error-content', 'info-content']),
        PropTypes.string]),
    text: PropTypes.string,
    textSize: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl']),
    marginTop: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    marginBottom: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px', '24px', '32px']),
    lineHeight: PropTypes.oneOf(['auto', 'none', 'tight', 'normal', 'relaxed', 'loose']),
    fontWeight: PropTypes.oneOf(['light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']),
    textAlign: PropTypes.oneOf(['auto', 'left', 'right', 'center', 'justify']),
    alignSelf: PropTypes.oneOf(['auto', 'start', 'end', 'center', 'stretch']),
    children: PropTypes.node
};*/