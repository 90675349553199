import { spacingMap } from '../helpers';

type DividerProps = {
    color?: 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'base-content' | 'primary' | 'accent' | 'success' | 'warning' | 'error' |
    'info' | 'success-surface' | 'warning-surface' | 'error-surface' | 'info-surface',
    margins?: '6px' | '8px' | '12px' | '16px' | '24px',
    direction?: 'horizontal' | 'vertical',
    thickness?: '0.5px' | '1px' | '2px' | '3px' | '4px',
    length?: 'full' | '3/4' | '2/3' | '1/2' | '1/3',
    __juno?: any,
}

export default function Divider({
        length = 'full',
        color = 'base-300',
        thickness = '1px',
        
        margins = null,
        
        direction = 'horizontal',
        __juno = {},
      }: DividerProps) {

    const marginStyles = direction == 'vertical' ? `h-${length} px-${spacingMap[margins]}` : `w-${length} py-${spacingMap[margins]}`
    
    const classes = `${marginStyles}`

    const inlineStyles = {
        height: direction == 'horizontal' ? thickness : '100%',
        width: direction == 'vertical' ? thickness : '100%',
        backgroundColor: `var(--${color})`
    }
    
    return (
        <div
        className={`${classes} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        >
            <div style={inlineStyles}/>
        </div>)
}



/* ignore rest */

Divider.definitions = {
    apiName: 'Divider',
    displayName: 'Divider',
    description: 'A divider component used to separate content, with customizable color, width, thickness, and margin. Supports horizontal and vertical orientations based on parent container direction.',
    ai_instructions: 'divider line. useful for separating groups of content',
    type: 'separators',
    relativeSize: 'small (thin)',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        thickness: {
            type: 'oneOf',
            options: ['0.5px', '1px', '2px', '3px', '4px'],
            displayName: 'Thickness',
            default: 'normal', 
            tile: '1/2'
        },
        length: {
            type: 'oneOf',
            options: ['full', '3/4', '2/3', '1/2', '1/3'],
            displayName: 'length',
            default: 'full', 
            tile: '1/2'
        },
        color: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-content', 'primary', 'accent', 'success', 'warning', 'error', 
    'info', 'success-surface', 'warning-surface', 'error-surface', 'info-surface'],
            displayName: 'Color',
            default: 'base-300'
        },
        margins: {
            type: 'spacing',
            options: ['6px', '8px', '12px', '16px', '24px'],
            displayName: 'Margins',
            default: 'xs', 
            tile: '1/2'
        },
        direction: {
            type: 'oneOf',
            options: ['horizontal', 'vertical'],
            displayName: 'Direction',
            default: 'horizontal', 
            ai_instructions: 'auto adapts direction based on the parent', 
            tile: '1/2'
        },
        
    }
};

/*Divider.propTypes = {
    color: PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'base-300', 'base-content', 'primary', 'accent', 'success', 'warning', 'error', 
    'info', 'success-surface', 'warning-surface', 'error-surface', 'info-surface']),
    margins: PropTypes.oneOf(['6px', '8px', '12px', '16px', '24px']),
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    thickness: PropTypes.oneOf(['0.5px', '1px', '2px', '3px', '4px']),
    length: PropTypes.oneOf(['full', '3/4', '2/3', '1/2', '1/3']),
};*/