import { Avatar } from '../';

type AvatarCardProps = {
    imageSize?: '16px' | '20px' | '24px' | '28px' | '32px' | '40px' | '48px',
    imageSrc?: string,
    imagePosition?: 'left' | 'right',
    imageColor?: 'auto' | 'base-0' | 'accent' | 'primary' | 'success' | 'error' | 'warning' | 'base-700',
    imageOnly?: boolean,
    type?: 'image' | 'initials',
    name?: string,
    secondaryText?: string,
    
    __juno?: any
}

export default function AvatarCard({ 
        imageSize = '28px',
        imageSrc = null,
        imageColor = 'accent',
        imageOnly = false,
        type = 'image',
        imagePosition = 'left',
        name = 'John Doe',
        secondaryText = null,
        
        __juno = {},
    }: AvatarCardProps) {

    const parsedSize = parseInt(imageSize)
    const gapStyle = parsedSize >= 42 ? 'gap-4' : parsedSize >=  33 ? 'gap-3' : parsedSize >= 28 ? 'gap-3' : 'gap-2'
    const classes = `flex flex-row items-center justify-start relative ${gapStyle}`
    
    const noSecondaryText = !secondaryText || secondaryText == '' || parsedSize < 24
    
    const titleSizeStyles = noSecondaryText 
        ? parsedSize >= 42 ? 'text-xl' : parsedSize >= 33 ? 'text-lg' : parsedSize >= 29 ? 'text-base' : parsedSize >= 20 ? 'text-sm' : 'text-xs'
        : parsedSize >= 42 ? 'text-lg' : parsedSize >= 33 ? 'text-base' : parsedSize >= 29 ? 'text-sm' : 'text-xs';
        
    
    const marginStyles = noSecondaryText ? '' :  parsedSize < 29 ? 'mb-0.5' : parsedSize < 33 ? 'mb-1' : parsedSize < 42 ? 'mb-1' : 'mb-1.5'
    const titleClasses = `font-medium leading-none ${titleSizeStyles} ${marginStyles}`

    const secondaryTextFontSize = parsedSize < 29 ? 'text-xs' : parsedSize < 33 ? 'text-xs' : parsedSize < 42 ? 'text-sm' : 'text-sm'
    const secondaryTextClasses = `text-current-50 leading-tight ${secondaryTextFontSize}`
    
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}
    console.log(name)
    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            >
         <Avatar size={imageSize} imageSrc={imageSrc} bgColor={imageColor} type={type} initials={name?.slice(0, 1)} />
         
         {!imageOnly &&
         <div 
            className={`flex flex-col ${imagePosition == 'left' ? 'justify-start text-left' : 'justify-end text-right'} text-current transition-all duration-75 ${imageOnly ? 'w-0 pointer-events-none opacity-0' : 'w-auto'}`}
            style={{...truncateStyle, order: imagePosition == 'left' ? 1 : -1}}>
            <h3 style={truncateStyle} className={titleClasses}>
                {name}
            </h3>
            {!noSecondaryText &&  <span style={truncateStyle} className={secondaryTextClasses}>
                {secondaryText}
            </span>}
         </div>}
        </div>
    )
}


/* ignore rest */

AvatarCard.definitions = {
    apiName: 'AvatarCard',
    displayName: 'AvatarCard',
    description: 'unlike just Avatar element this one has name and @username',
    ai_instructions: 'unlike just Avatar element this one has name and @username',
    type: 'cards',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {

        name: {
            type: 'string',
            default: 'John Doe', 
            ai_instructions: 'typically name or similar, looks more prominent than secondaryText', 
            displayName: 'Name', 
            

        },
        secondaryText: {
            type: 'string',
            editable: true,
            default: null, 
            ai_instructions: 'could be role, @username, company or similar', 
            displayName: 'Secondary Text'
        },
        imageSize: {
            type: 'oneOf',
            options: ['16px', '20px', '24px', '28px', '32px', '40px', '48px'],
            default: '28px', 
            displayName: 'Image Size', 
            tile: '1/2',
        },
        type: {
            type: 'oneOf',
            options: ['image', 'initials'],
            default: 'image', 
            displayName: 'Avatar Type',
            tile: '1/2',
        },
        
        imagePosition: {
            type: 'oneOf',
            options: ['left', 'right'],
            default: 'left', 
            displayName: 'Image Position', 
            tile: '1/2',
        },
        imageColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-700', 'accent', 'primary', ],
            default: 'accent', 
            displayName: 'Color',
        },
        
        imageOnly: {
            type: 'bool',
            displayName: 'Image Only',
            default: false, 
            ai_instructions: 'removes name and secondaryText', 
            tile: '1/2',
        }, 
        imageSrc: {
            type: 'imageURL',
            default: null,
            displayName: 'Image',
            ai_instructions: 'null will be placeholder image'
        },
    }
}
/*AvatarCard.propTypes = {
    imageSize: PropTypes.oneOf(['16px', '20px', '24px', '28px', '32px', '40px', '48px']),
    imageSrc: PropTypes.string,
    imagePosition: PropTypes.oneOf(['left', 'right']),
    imageColor: PropTypes.oneOf(['auto', 'base-0', 'accent', 'primary', 'success', 'error', 'warning', 'base-700']),
    type: PropTypes.oneOf(['image', 'initials']),
    name: PropTypes.string,
    secondaryText: PropTypes.string,
    corners: PropTypes.oneOf(["none", "sm", "md"]),
    hasOutline: PropTypes.bool,
    imageOnly: PropTypes.bool, 
    tooltipText: PropTypes.string
};  */