import { useState } from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
  closestCorners,
} from '@dnd-kit/core';
import {
  SortableContext,
  rectSortingStrategy,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function TestPage() {
  const [folders, setFolders] = useState([
    { id: '1', name: 'Folder 1', index: 1 },
    { id: '2', name: 'Folder 2', index: 2 },
  ]);

  const [items, setItems] = useState([
    { id: 'A', folderId: '1', index: 1 },
    { id: 'B', folderId: '1', index: 2 },
    { id: 'C', folderId: '2', index: 1 },
    { id: 'D', folderId: '2', index: 2 },
  ]);

  const [activeId, setActiveId] = useState(null);
  const [activeType, setActiveType] = useState(null); // 'folder' or 'item'

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      // Press delay for touch devices
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
    setActiveType(active.data.current?.type);
  };

  const handleDragOver = (event) => {
    const { active, over } = event;
    if (!over) return;

    const activeType = active.data.current?.type;
    const overType = over.data.current?.type;

    console.log('drag over', overType);
    if (activeType === 'item') {
      const activeItem = items.find((item) => item.id === active.id);
      const overFolderId =
        overType === 'folder' ? over.id : over.data.current.folderId;

      if (activeItem.folderId !== overFolderId) {
        setItems((prevItems) =>
          prevItems.map((item) =>
            item.id === active.id
              ? { ...item, folderId: overFolderId }
              : item
          )
        );
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) {
      setActiveId(null);
      setActiveType(null);
      return;
    }

    const activeType = active.data.current?.type;
    const overType = over.data.current?.type;

    if (activeType === 'folder' && overType === 'folder') {
      // Reorder folders
      const oldIndex = folders.findIndex((folder) => folder.id === active.id);
      const newIndex = folders.findIndex((folder) => folder.id === over.id);

      const updatedFolders = arrayMove(folders, oldIndex, newIndex).map(
        (folder, index) => ({ ...folder, index: index + 1 })
      );
      setFolders(updatedFolders);
    } else if (activeType === 'item') {
      const activeItem = items.find((item) => item.id === active.id);
      const overItem = items.find((item) => item.id === over.id);

      if (activeItem.folderId === overItem.folderId) {
        // Reorder items within the same folder
        const itemsInFolder = items
          .filter((item) => item.folderId === activeItem.folderId)
          .sort((a, b) => a.index - b.index);

        const oldIndex = itemsInFolder.findIndex(
          (item) => item.id === active.id
        );
        const newIndex = itemsInFolder.findIndex(
          (item) => item.id === over.id
        );

        const reorderedItems = arrayMove(itemsInFolder, oldIndex, newIndex);

        const updatedItems = items.map((item) => {
          if (item.folderId === activeItem.folderId) {
            const newIndex = reorderedItems.findIndex(
              (i) => i.id === item.id
            );
            return { ...item, index: newIndex + 1 };
          }
          return item;
        });

        setItems(updatedItems);
      }
    }

    setActiveId(null);
    setActiveType(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={folders.sort((a, b) => a.index - b.index).map((f) => f.id)}
        strategy={verticalListSortingStrategy}
      >
        <div className="flex flex-col mx-auto p-10 gap-10 mt-20 w-full items-start justify-center">
          {folders
            .sort((a, b) => a.index - b.index)
            .map((folder) => (
              <SortableFolder
                key={folder.id}
                folder={folder}
                items={items.filter(
                  (item) => item.folderId === folder.id
                )}
              />
            ))}
        </div>
      </SortableContext>

      
    </DndContext>
  );
}

function SortableFolder({ folder, items }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: folder.id,
    data: { type: 'folder' },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex flex-col gap-5 p-5 border w-40 bg-white"
      {...attributes}
    >
      <div className="flex items-center justify-between">
        <span>{folder.name}</span>
        <span
          className="cursor-pointer"
          {...listeners}
        >
          ⠿
        </span>
      </div>
      <SortableContext
        items={items.sort((a, b) => a.index - b.index).map((item) => item.id)}
        strategy={verticalListSortingStrategy}
      >
        {items
          .sort((a, b) => a.index - b.index)
          .map((item) => (
            <SortableItem
              key={item.id}
              id={item.id}
              folderId={folder.id}
            />
          ))}
      </SortableContext>
    </div>
  );
}

function SortableItem({ id, folderId }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: { type: 'item', folderId },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex bg-yellow-200 border w-20 h-10 items-center justify-center"
      {...attributes}
      {...listeners}
    >
      {id}
    </div>
  );
}